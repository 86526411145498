<script>
	import { required, email, maxLength, numeric, minLength } from "vuelidate/lib/validators"
	import appConfig from "@/app.config"
	import Vue from 'vue'
	import axios from 'axios'
	import VueAxios from 'vue-axios'
	
	Vue.use(VueAxios, axios)
	axios.defaults.baseURL = process.env.VUE_APP_BASE_URL
	axios.defaults.headers.common['Access-Control-Allow-Origin'] = process.env.VUE_APP_BASE_URL
	
	import VueSession from 'vue-session'
	Vue.use(VueSession)
	/**
	 * Login component
	 */
	export default {
		page: {
			title: "Login",
			meta: [
				{
					name: "description",
					content: appConfig.description,
				},
			],
		},
		data() {
			return {
				email: "",
				password: "",
				submitted: false,
				isNumberExisted: true,
				authError: "sds",
				tryingToLogIn: false,
				isAuthError: false,
				mobileNumber: "",
				otp: "",
				countryCode: "91",
				isOtpSend: false,
				isValidOtp: true,
				isGetOtpDisabled: false,
			};
		},
		validations: {
			email: {
				required,
				email,
			},
			password: {
				required,
			},
			mobileNumber: {
				required,
				maxLength: maxLength(10),
				minLength: minLength(10),
				numeric,
			},
			otp: {
				required,
				maxLength: maxLength(6),
				minLength: minLength(6),
				numeric,
			},
		},
		computed: {
			notification() {
				return this.$store ? this.$store.state.notification : null;
			},
			notificationAutoCloseDuration() {
				return this.$store && this.$store.state.notification ? 5 : 0;
			},
		},
		created: function() {},
		mounted() {
			document.body.classList.add("authentication-bg")
		},
		methods: {
			autoLogin: function () {
				this.axios.post('auto-login', {
					hashedUrl: this.$route.query.auth,
				}).then(result => {
					let status = result.data.responseCode;
					if (status == 200) {
						if (result.data) {
							//console.log(result.data.data.adminData);
							// store user details and jwt token in local storage to keep user logged in between page refreshes
							localStorage.setItem('user', JSON.stringify(result.data.data.loginDetailsAdmin));
							localStorage.setItem('authToken', result.data.data.token);
							this.$router.push('/tables/attendance');
						}
					}
				}).catch(error => {
					console.log(error)
				});
			},
			getMenus: function() {
				this.axios.get("menuList", {
					headers: {
						'Authorization': 'Bearer ' + localStorage.getItem('authToken'),
					},
				}).then((response) => {
					console.log(response.data)
					localStorage.setItem('menus', JSON.stringify(response.data.data))
				})
			},
			logingWithOtp: function () {
				this.submitted = true;
				// stop here if form is invalid
				this.axios.post('login-verifyotp', {
					phoneNumber: this.mobileNumber,
					otp: this.otp
				}).then((result) => {
					const status = result.data.responseCode
					if (status == 200) {
						localStorage.setItem('user', JSON.stringify(result.data.data.loginDetailsAdmin))
						localStorage.setItem('authToken', result.data.data.token)
						this.getMenus()
						this.$router.push('/tables/attendance')
					}
					if (status == 400) {
						this.isValidOtp = false;
						this.$toast.error(result.data.data, {
							position: "top-right",
							timeout: 5000,
							closeOnClick: true,
							pauseOnFocusLoss: true,
							pauseOnHover: true,
							draggable: true,
							draggablePercent: 0.6,
							showCloseButtonOnHover: false,
							hideProgressBar: false,
							closeButton: "button",
							icon: true,
							rtl: false
						})
					}
				}).catch((error) => {
					console.log(error)
				})
			},
			getOtp: function () {
				this.submitted = true;
				this.$v.$touch();
				if (!this.$v.mobileNumber.$invalid) {
					this.submitted = false;
					this.axios.post('login-otp', {
						phoneNumber: this.mobileNumber,
					}).then((result) => {
						if (result.data.responseCode === 400) {
							this.isOtpSend = false
							//this.isNumberExisted = false
							this.$toast.error("Entered number does not exist", {
								position: "top-right",
								timeout: 5000,
								closeOnClick: true,
								pauseOnFocusLoss: true,
								pauseOnHover: true,
								draggable: true,
								draggablePercent: 0.6,
								showCloseButtonOnHover: false,
								hideProgressBar: false,
								closeButton: "button",
								icon: true,
								rtl: false
							})
						}
						if (result.data.responseCode === 200) {
							this.isOtpSend = true;
							this.$nextTick(() => {
								this.$refs.otpInput.focus()
							})
						}
					}).catch((error) => {
						console.log(error)
					});
				}
			},
		},
	};
</script>
<template>
	<div>
		<div class="account-pages my-5 pt-sm-5">
			<div class="container" id="container">
				<div class="overlay-container">
					<div class="overlay">
						<div class="overlay-panel overlay-left">
							<img src="@/assets/images/munshi.png" alt height="60" class="logo logo-dark"
								style="object-fit:cover;" />
							<p>Your HRMS on Whatsapp</p>
						</div>
					</div>
				</div>
				<div class="form-container sign-in-container">
					<b-form @submit.prevent="logingWithOtp">
						<h3>Sign In to Munshi</h3>
						<div>
							<div class="d-flex">
								<div class="col-2">
									<input type="text" v-model="countryCode" class="form-control" value="+91" readonly/>
								</div>
								<div class="col-10">
									<input
										type="text"
										v-model="mobileNumber"
										class="form-control"
										:class="{ 'is-invalid': !isNumberExisted}"
										placeholder="Your Number"
										v-on:keyup.enter="getOtp" />
									<div v-if="!isNumberExisted" class="invalid-feedback">
										<span>Entered number does not exist</span>
									</div>
								</div>
							</div>
							<div style="text-align: left;">Otp will be sent to this number</div>
						</div>
						<div class="col-12 mt-2" v-if="isOtpSend">
							<input type="text" v-model="otp" ref="otpInput" class="form-control" placeholder="Enter OTP" />
						</div>
						<p v-if="isOtpSend">Did not receive OTP? <b-button class="ghost" variant="light"  @click="getOtp">Resend OTP</b-button></p>
						<div class="mt-3 text-end">
							<button type="button" v-if="!isOtpSend" @click="getOtp">Get OTP</button>
							<button type="submit" v-if="isOtpSend">Verify</button>
						</div>
					</b-form>
				</div>
			</div>
		</div>
	</div>
</template>
<style lang="scss" scoped>
	@import url('https://fonts.googleapis.com/css?family=Montserrat:400,800');
	* {
		box-sizing: border-box;
	}
	body {
		background: #f6f5f7;
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
		font-family: 'Montserrat', sans-serif;
		height: 100vh;
		margin: -20px 0 50px;
	}
	h1 {
		font-weight: bold;
		margin: 0;
	}
	h2 {
		text-align: center;
	}
	p {
		font-size: 14px;
		font-weight: 100;
		line-height: 20px;
		letter-spacing: 0.5px;
		margin: 20px 0 30px;
	}
	span {
		font-size: 12px;
	}
	a {
		color: #333;
		font-size: 14px;
		text-decoration: none;
		margin: 15px 0;
	}
	button {
		border-radius: 20px;
		border: 1px solid #F12711;
		background-color: #FF4B2B;
		color: #FFFFFF;
		font-size: 12px;
		font-weight: bold;
		padding: 12px 45px;
		letter-spacing: 1px;
		text-transform: uppercase;
		transition: transform 80ms ease-in;
	}
	button:active {
		transform: scale(0.95);
	}
	button:focus {
		outline: none;
	}
	button.ghost {
		background-color: transparent;
		border-color: #FFFFFF;
		color: #F12711;
	}
	form {
		background-color: #FFFFFF;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		padding: 0 50px;
		height: 100%;
		text-align: center;
	}
	input {
		background-color: #eee;
		border: none;
		padding: 12px 15px;
		margin: 8px 0;
		width: 100%;
	}
	.container {
		background-color: #fff;
		border-radius: 10px;
		box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25),
			0 10px 10px rgba(0, 0, 0, 0.22);
		position: relative;
		overflow: hidden;
		width: 768px;
		max-width: 100%;
		min-height: 480px;
	}
	.form-container {
		position: absolute;
		top: 0;
		height: 100%;
		transition: all 0.6s ease-in-out;
	}
	.sign-in-container {
		left: 50%;
		width: 50%;
		z-index: 2;
	}
	.container.right-panel-active .sign-in-container {
		transform: translateX(100%);
	}
	.sign-up-container {
		left: 50%;
		width: 50%;
		opacity: 0;
		z-index: 1;
	}
	.container.right-panel-active .sign-up-container {
		transform: translateX(100%);
		opacity: 1;
		z-index: 5;
		animation: show 0.6s;
	}
	@keyframes show {
		0%,
		49.99% {
			opacity: 0;
			z-index: 1;
		}
		50%,
		100% {
			opacity: 1;
			z-index: 5;
		}
	}
	.overlay-container {
		position: absolute;
		top: 0;
		left: 0;
		width: 50%;
		height: 100%;
		overflow: hidden;
		transition: transform 0.6s ease-in-out;
		z-index: 100;
	}
	.container.right-panel-active .overlay-container {
		transform: translateX(-100%);
	}
	.overlay {
		background: #F16211;
		background: -webkit-linear-gradient(to right, #F12711, #FFCD00);
		background: linear-gradient(to bottom, #F12711, #FFCD00);
		background-repeat: no-repeat;
		background-size: cover;
		background-position: 0 0;
		color: #FFFFFF;
		position: relative;
		left: -100%;
		height: 100%;
		width: 200%;
		transform: translateX(0);
		transition: transform 0.6s ease-in-out;
	}
	.container.right-panel-active .overlay {
		transform: translateX(50%);
	}
	.overlay-panel {
		position: absolute;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		padding: 0 40px;
		text-align: center;
		top: 0;
		height: 100%;
		width: 50%;
		transform: translateX(0);
		transition: transform 0.6s ease-in-out;
	}
	.overlay-left {
		transform: translateX(100%);
	}
	.container.right-panel-active .overlay-left {
		transform: translateX(0);
	}
	.overlay-right {
		right: 0;
		transform: translateX(0);
	}
	.container.right-panel-active .overlay-right {
		transform: translateX(20%);
	}
	.social-container {
		margin: 20px 0;
	}
	footer {
		background-color: #222;
		color: #fff;
		font-size: 14px;
		bottom: 0;
		position: fixed;
		left: 0;
		right: 0;
		text-align: center;
		z-index: 999;
	}
	footer p {
		margin: 10px 0;
	}
	footer i {
		color: red;
	}
	footer a {
		color: #3c97bf;
		text-decoration: none;
	}
</style>